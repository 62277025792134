<template>
    <div class="vs-row">
        <vx-card actionable class="cardx" title="Purchases & Receipts">
            <div class="gap">
                <vs-row>

                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="11"
                    >
                        <!-- <b style="color: gray; margin-right: 2%">{{
                            identityCityCode
                        }}</b>
                        <div
                            style="
                                margin-right: 2%;
                                width: 250px;
                                z-index: 1000;
                            "
                            v-show="
                                getrole('cm4') ||
                                getrole('cm3') ||
                                getrole('fi1') ||
                                getrole('wc') ||
                                getrole('ac2') ||
                                getrole('wh1') ||
                                getrole('wh2')
                            "
                        >
                            <v-select
                                id="courseselect"
                                class="w-full"
                                placeholder="City"
                                v-model="city"
                                :options="cities"
                                label="text"
                            ></v-select>
                        </div> -->
                        <p style="margin-right: 1%">Can-ID</p>
                      <vs-input v-model="canid" />
                        <vs-button
                            radius
                            color="dark"
                            type="gradient"
                            icon="search"
                            @click="openAddReceipt()"
                            style="margin-left: 1%"
                        ></vs-button>
                        <vs-button
                            radius
                            color="dark"
                            type="gradient"
                            icon="refresh"
                            @click="refreshReceipts()"
                            style="margin-left: 1%"
                        ></vs-button>
                    </vs-col>
                </vs-row>
            </div>
            <div class="gap">
                <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12"> -->
                <vs-table :data="receipts" id="dummy" v-if="visible">
                    <!-- <template slot="header">
            <h3>Enrollments</h3>
          </template>-->
                    <template slot="thead">
                        <vs-th>Can-ID</vs-th>
                        <vs-th>Name</vs-th>
                        <!-- <vs-th>Subjects Chosen</vs-th> -->
                        <!-- <vs-th>City</vs-th> -->
                        <!-- <vs-th>Course</vs-th> -->
                        <vs-th>Type</vs-th>
                        <vs-th>Name</vs-th>
                        <!-- <vs-th>Mode</vs-th>
            <vs-th>Mode of Study</vs-th>
            <vs-th>Category</vs-th>-->
                        <vs-th>Agreed Cost</vs-th>
                        <vs-th>Paid</vs-th>
                        <!-- <vs-th>Amount In Process</vs-th> -->
                        <vs-th>Pending</vs-th>
                        <vs-th>EF</vs-th>
                        <!-- <vs-th>Actual Cost</vs-th> -->
                        <!-- <vs-th>Batch</vs-th> -->
                        <!-- <vs-th>Date of Enrollment</vs-th> -->
                        <vs-th v-if="!getrole('cm1')">Add Receipt</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <!-- <div :color="getdefaulterColor(item)"> -->
                            <vs-tr :state="getdefaulterColor(item)" :key="item.spoc_name" v-for="item in data">

                                    <vs-td :data="item.spoc_name">
                                        <div>{{ item.identity }}</div>
                                    </vs-td>

                                    <vs-td :data="item.call_utilization">
                                        <div>{{ item.person_name }}</div>
                                    </vs-td>

                                    <vs-td :data="item.call_utilization">
                                        <div>{{ item.type }}</div>
                                    </vs-td>

                                    <vs-td :data="item.call_utilization">
                                        <div>{{ item.name }}</div>
                                    </vs-td>

                                    <vs-td :data="item.untracked_in_calls_count">
                                        <div>{{ item.agreed_cost }}</div>
                                    </vs-td>

                                    <vs-td :data="item.missed_call_count">
                                        <div>{{ sumOfAmount(item.receipts) }}</div>
                                    </vs-td>

                                    <vs-td :data="item.missed_call_count">
                                        <div>
                                            {{
                                                item.agreed_cost -
                                                sumOfAmount(item.receipts)
                                            }}
                                        </div>
                                    </vs-td>

                                    <vs-td :data="item.enrollment_id">
                                        <div v-if="item.enrollment_id != null">Found</div>
                                        <div v-if="item.enrollment_id == null">Not Found</div>
                                    </vs-td>

                                    <vs-td
                                        :data="item.nuc_count"
                                        v-if="!getrole('cm1')"
                                    >
                                        <div>
                                            <vs-row>
                                                <vx-tooltip text="Add Receipt">
                                                    <vs-button
                                                        color="dark"
                                                        type="gradient"
                                                        icon="add"
                                                        @click="addenrollment(item)"
                                                    ></vs-button>
                                                </vx-tooltip>
                                                <vx-tooltip text="Mark As Defaulter">
                                                    <vs-button
                                                    style="margin-left: 5%"
                                                    icon="swap_horizontal_circle"
                                                    type="gradient"
                                                    color="dark"
                                                    @click="markAsDefaulter(item)"
                                                    ></vs-button>
                                                </vx-tooltip>
                                            </vs-row>
                                        </div>
                                        <div style="margin-top: 5%;margin-left: -6%;">
                                            <vs-row>
                                                <vx-tooltip text="Delete Receipt">
                                                <vs-button
                                                    v-if="
                                                        item.name === 'Miscellaneous' &&
                                                        item.amount_pending === 2500
                                                    "
                                                    color="danger"
                                                    type="gradient"
                                                    icon="delete"
                                                    @click="Removepurchase(item)"
                                                ></vs-button>
                                                </vx-tooltip>
                                                <vx-tooltip text="Delete Receipt">
                                                <vs-button
                                                    style="margin-left: 5%"
                                                    v-if="
                                                        item.receipts.length === 0 &&
                                                        item.name !== 'Miscellaneous'
                                                    "
                                                    color="danger"
                                                    type="gradient"
                                                    icon="delete"
                                                    @click="DeletePurchase(item)"
                                                ></vs-button>
                                                </vx-tooltip>
                                            </vs-row>
                                        </div>
                                    </vs-td>

                                <template slot="expand">
                                    <vs-row>
                                        <vs-col
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="center"
                                            vs-w="12"
                                        >
                                            <div style="width: 100%">
                                                <vs-table :data="item.receipts">
                                                    <template slot="thead">
                                                        <vs-th>Can-ID</vs-th>
                                                        <vs-th>Person Name</vs-th>
                                                        <vs-th
                                                            >Transaction Type</vs-th
                                                        >
                                                        <vs-th>Cheque No</vs-th>
                                                        <vs-th>PDC Date</vs-th>
                                                        <vs-th
                                                            >Transaction Id</vs-th
                                                        >
                                                        <vs-th>TID</vs-th>
                                                        <vs-th>City</vs-th>
                                                        <vs-th>Receipt No</vs-th>
                                                        <vs-th>Amount</vs-th>
                                                        <vs-th>Receipt Date</vs-th>
                                                        <vs-th
                                                            v-if="
                                                                getUserId(132) ||
                                                                getUserId(139) ||
                                                                getUserId(1499) ||
                                                                getUserId(927) ||
                                                                getUserId(1299)
                                                            "
                                                            >Deposit Date</vs-th
                                                        >
                                                        <vs-th
                                                            v-if="
                                                                getUserId(132) ||
                                                                getUserId(139) ||
                                                                getUserId(1499) ||
                                                                getUserId(927) ||
                                                                getUserId(39) ||
                                                                getUserId(3154) ||
                                                                getUserId(1299) ||
                                                                getUserId(122) ||
                                                                getUserId(141) ||
                                                                getUserId(2422) || 
                                                                getUserId(3267)
                                                            "
                                                            >MR Number</vs-th
                                                        >
                                                        <vs-th
                                                            v-if="
                                                                getUserId(132) ||
                                                                getUserId(139) ||
                                                                getUserId(1499) ||
                                                                getUserId(927) ||
                                                                getUserId(39) ||
                                                                getUserId(3154) ||
                                                                getUserId(1299) ||
                                                                getUserId(122) ||
                                                                getUserId(141) ||
                                                                getUserId(2422) ||
                                                                getUserId(3267)
                                                            "
                                                            >UTR Number</vs-th
                                                        >
                                                        <vs-th v-if="
                                                                    getUserId(
                                                                        132
                                                                    ) ||
                                                                    getUserId(
                                                                        122
                                                                    ) ||
                                                                    getUserId(77) ||
                                                                    getUserId(39)  ||
                                                                 CheckTeam('Accounts')
                                                                ">Action</vs-th>
                                                        <vs-th v-if="
                                                                    getUserId(
                                                                        132
                                                                    ) ||
                                                                    getUserId(
                                                                        122
                                                                    ) ||
                                                                    getUserId(77) ||
                                                                    getUserId(39)  ||
                                                                 CheckTeam('Accounts')
                                                                ">Transfer Receipts</vs-th>
                                                    </template>

                                                    <template slot-scope="{ data }">
                                                        <vs-tr
                                                            :key="indextr"
                                                            v-for="(
                                                                tr, indextr
                                                            ) in data"
                                                        >
                                                            <vs-td>{{
                                                                tr.identity
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.person_name
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.transaction_type
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.cheque_no
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.pde_date
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.transaction_id
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.cc_machine_tid_no
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.city
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.receipt_no
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                tr.amount
                                                            }}</vs-td>
                                                            <vs-td>{{
                                                                unixTimestampConvert(
                                                                    tr.receipt_date
                                                                )
                                                            }}</vs-td>
                                                            <vs-td
                                                                v-if="
                                                                    getUserId(
                                                                        132
                                                                    ) ||
                                                                    getUserId(
                                                                        139
                                                                    ) ||
                                                                    getUserId(927) ||
                                                                getUserId(1299) ||
                                                                    getUserId(1499)
                                                                "
                                                                >{{
                                                                    unixTimestampConvert(
                                                                        tr.deposit_date
                                                                    )
                                                                }}</vs-td
                                                            >
                                                            <vs-td
                                                                v-if="
                                                                    getUserId(
                                                                        132
                                                                    ) ||
                                                                    getUserId(
                                                                        139
                                                                    ) ||
                                                                    getUserId(1499) ||
                                                                    getUserId(927) ||
                                                                    getUserId(39) ||
                                                                    getUserId(1299) ||
                                                                    getUserId(3154) ||
                                                                    getUserId(122) ||
                                                                    getUserId(141) ||
                                                                    getUserId(2422) ||
                                                                    getUserId(3267)
                                                                "
                                                                >{{
                                                                    tr.mr_number
                                                                }}</vs-td
                                                            >
                                                            <vs-td
                                                                v-if="
                                                                    getUserId(132) ||
                                                                    getUserId(139) ||
                                                                    getUserId(1499) ||
                                                                    getUserId(927) ||
                                                                    getUserId(39) ||
                                                                    getUserId(1299) ||
                                                                    getUserId(3154) ||
                                                                    getUserId(122) ||
                                                                    getUserId(141) ||
                                                                    getUserId(2422) ||
                                                                    getUserId(3267)
                                                                "
                                                                >{{
                                                                    tr.utr_no
                                                                }}</vs-td
                                                            >
                                                            <vs-td  v-if="
                                                                    getUserId(
                                                                        132
                                                                    ) ||
                                                                    getUserId(
                                                                        122
                                                                    ) ||
                                                                    getUserId(77) ||
                                                                    getUserId(39) ||
                                                                 CheckTeam('Accounts')
                                                                ">
                                                                <vs-button
                                                                    size="small"
                                                                    color="rgb(134, 4, 98)"
                                                                    type="filled"
                                                                    @click="
                                                                        split(tr, item)
                                                                    "
                                                                    >Split
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    Receipt</vs-button
                                                                >
                                                                <vs-button
                                                                    style="
                                                                        margin-top: 4%;
                                                                    "
                                                                    size="small"
                                                                    color="rgb(134, 4, 98)"
                                                                    type="filled"
                                                                    @click="
                                                                        resendReceipt(
                                                                            tr
                                                                        )
                                                                    "
                                                                    >Resend
                                                                    Receipt</vs-button
                                                                >
                                                            </vs-td>
                                                            <vs-td v-if="
                                                                    getUserId(
                                                                        132
                                                                    ) ||
                                                                    getUserId(
                                                                        122
                                                                    ) ||
                                                                    getUserId(77) ||
                                                                    getUserId(39) ||
                                                                 CheckTeam('Accounts')
                                                                ">
                                                            <vs-row>
                                                                <vx-tooltip text="Transfer Receipt">
                                                                <vs-button
                                                                    style="margin-left: 0%"
                                                                    color="dark"
                                                                    type="gradient"
                                                                    @click="transferreceiptpopup(tr)"
                                                                    icon="redo"
                                                                    ></vs-button>
                                                                </vx-tooltip>
                                                                <vx-tooltip text="Delete Receipt">
                                                                <vs-button
                                                                    style="margin-left: 4%"
                                                                    color="danger"
                                                                    type="gradient"
                                                                    icon="delete"
                                                                    @click="deleteReceiptData(tr)"
                                                                    ></vs-button>
                                                                </vx-tooltip>
                                                                <!-- <vs-button
                                                                    style="margin-top: 6%;"
                                                                    color="dark"
                                                                    type="gradient"
                                                                    @click="anonmousPurchase(tr)"
                                                                    icon="info"
                                                                    ></vs-button> -->
                                                            </vs-row>
                                                            </vs-td>
                                                        </vs-tr>
                                                    </template>
                                                </vs-table>
                                            </div>
                                        </vs-col>
                                    </vs-row>
                                </template>
                            </vs-tr>
                        <!-- </div> -->
                    </template>
                </vs-table>
                <vs-popup
                    class="TransferpopUp"
                    title="Transfer Receipt popup"
                    :active.sync="transfer_receipt_popup"
                >
                    <vs-row class="gap">
                        <vs-col vs-w="10"
                            ><vs-input
                                class="inputx"
                                placeholder="Search Can-ID"
								v-model="serchidentity"
                            />
                        </vs-col>
                        <vs-col vs-w="2">
                            <vs-button
                                color="primary"
                                type="filled"
                                icon="search"
                                @click="transferreceiptdetails()"
                            ></vs-button>
                        </vs-col>
                    </vs-row>

                    <div v-show="transfer_receipt_details">
                        <!-- <vs-row class="gap">
                            <vs-col vs-w="6">Can-ID: {{ mwb.identity }}</vs-col>
                            <vs-col vs-w="6">Name: {{ mwb.person_name }}</vs-col>
                        </vs-row>
                         -->
                        <vs-table :data="purchance_data">
                        <template slot="thead">
                            <th></th>
                            <vs-th>Can-ID</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>Type</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>Agreed Cost</vs-th>
                            <vs-th>Paid</vs-th>
                            <vs-th>Pending</vs-th>
                            <vs-th>EF</vs-th>
                        </template>
                        <template slot-scope="{ data }">
                            <vs-tr :key="item.id" v-for="item in data">
                                    <vs-td :data="item">
                                        <vs-radio
                                            color="#0044ba"
                                            class="myRadioBtn"
                                            v-model="selected_purchase"
                                            :vs-value="item"
                                            >
                                        </vs-radio>
                                    </vs-td>
                                    <vs-td :data="item.identity">
                                        <div>{{ item.identity }}</div>
                                    </vs-td>

                                    <vs-td :data="item.person_name">
                                        <div>{{ item.person_name }}</div>
                                    </vs-td>

                                    <vs-td :data="item.type">
                                        <div>{{ item.type }}</div>
                                    </vs-td>

                                    <vs-td :data="item.name">
                                        <div>{{ item.name }}</div>
                                    </vs-td>

                                    <vs-td :data="item.agreed_cost">
                                        <div>{{ item.agreed_cost }}</div>
                                    </vs-td>
                                    <vs-td :data="item.amount_paid">
                                        <div>{{ getSum(item.amount_paid, item.amount_in_process) }}</div>
                                    </vs-td>

                                    <vs-td :data="item.amount_pending">
                                        <div>{{ item.amount_pending }}</div>
                                    </vs-td>

                                    <vs-td :data="item.enrollment_id">
                                        <div v-if="item.enrollment_id != null">Found</div>
                                        <div v-if="item.enrollment_id == null">Not Found</div>
                                    </vs-td>
                            </vs-tr>
                        </template>
                        </vs-table>
                        <!-- <vs-row class="gap">
                            <vs-col vs-w="6"> <p>City: {{ mwb.city }}</p></vs-col>
                            <vs-col vs-w="6">
                                <p>Transaction Amount: {{ this.transferAmount }}</p>
                            </vs-col>
                        </vs-row> -->
                        <!-- <vs-row class="gap">
                            <vs-col vs-w="6"> <v-select

                                    class="w-full"
                                    placeholder="Type"
                                    v-model="transaction_type"
                                    :options="Transactiontypes"
                                ></v-select></vs-col>
                            <vs-col vs-w="6">
                                <v-select

                                    class="w-full"
                                    placeholder="Course"
                                    v-model="Transfercourse"
                                    :options="Transfercoursetypes"
                                ></v-select>
                            </vs-col>
                        </vs-row> -->
                        <vs-row class="gap popup_button">
							<vs-col vs-w="4"></vs-col>
							<vs-col vs-w="4">
								<vs-button
									@click="TransferReceipt()"
									color="primary"
									type="filled"
									>Transfer Receipt</vs-button
								>
							</vs-col>
							<vs-col vs-w="4"></vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <vs-popup
                    class="holamundo"
                    :title=" 'Split Receipt - ' +
                        splitdata.person_name +
                        '  ,  Date: ' +
                        convertTimestampToDate(splitdata.receipt_date) + ' , ' + split_wise_course
                    "
                    :active.sync="splitreceiptpopup"
                >
                    <div>
                        <vs-card>
                                <vs-row class="gap">
                                    <vs-col vs-w="7"
                                        ><span><b>Transaction Type : </b></span>
                                        <span>{{
                                            splitdata.transaction_type
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="5"
                                        ><span><b>Transaction Id : </b></span>
                                        <span>{{
                                            splitdata.transaction_id
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Amount : </b></span>
                                        <span>{{
                                            splitdata.amount
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span
                                            ><b
                                                >Receipt Added By [Spoc] :
                                            </b></span
                                        >
                                        <span>{{
                                            splitdata.added_by_name
                                        }}</span></vs-col
                                    >
                                </vs-row>
                        </vs-card>
                        <vs-row class="gap">
                            <vs-col vs-w="6">
                                <v-select
                                    v-model="purchase_type"
                                    :options="purchase_types"
                                    placeholder="type"
                                ></v-select>
                            </vs-col>
                            <vs-col vs-w="6">
                                <vs-input
                                    v-model="purchase_amount"
                                    placeholder="amount"
                                ></vs-input>
                            </vs-col>
                        </vs-row>
                        <vs-row class="gap">
                            <vs-col vs-w="12">
                                <div v-show="split_receipt_details">
                                    <vs-table :data="purchance_data">
                                    <template slot="thead">
                                        <th></th>
                                        <vs-th>Can-ID</vs-th>
                                        <vs-th>Name</vs-th>
                                        <vs-th>Type</vs-th>
                                        <vs-th>Name</vs-th>
                                        <vs-th>Agreed Cost</vs-th>
                                        <vs-th>Paid</vs-th>
                                        <vs-th>Pending</vs-th>
                                        <vs-th>EF</vs-th>
                                    </template>
                                    <template slot-scope="{ data }">
                                        <vs-tr :key="item.id" v-for="item in data">
                                                <vs-td :data="item">
                                                    <vs-radio
                                                        color="#0044ba"
                                                        class="myRadioBtn"
                                                        v-model="selected_purchase"
                                                        :vs-value="item"
                                                        >
                                                    </vs-radio>
                                                </vs-td>
                                                <vs-td :data="item.identity">
                                                    <div>{{ item.identity }}</div>
                                                </vs-td>

                                                <vs-td :data="item.person_name">
                                                    <div>{{ item.person_name }}</div>
                                                </vs-td>

                                                <vs-td :data="item.type">
                                                    <div>{{ item.type }}</div>
                                                </vs-td>

                                                <vs-td :data="item.name">
                                                    <div>{{ item.name }}</div>
                                                </vs-td>

                                                <vs-td :data="item.agreed_cost">
                                                    <div>{{ item.agreed_cost }}</div>
                                                </vs-td>
                                                <vs-td :data="item.amount_paid">
                                                    <div>{{ getSum(item.amount_paid, item.amount_in_process) }}</div>
                                                </vs-td>

                                                <vs-td :data="item.amount_pending">
                                                    <div>{{ item.amount_pending }}</div>
                                                </vs-td>

                                                <vs-td :data="item.enrollment_id">
                                                    <div v-if="item.enrollment_id != null">Found</div>
                                                    <div v-if="item.enrollment_id == null">Not Found</div>
                                                </vs-td>
                                        </vs-tr>
                                    </template>
                                    </vs-table>
                                    <!-- <vs-row class="gap popup_button">
                                        <vs-col vs-w="4"></vs-col>
                                        <vs-col vs-w="4">
                                            <vs-button
                                                @click="TransferReceipt()"
                                                color="primary"
                                                type="filled"
                                                >Transfer Receipt</vs-button
                                            >
                                        </vs-col>
                                        <vs-col vs-w="4"></vs-col>
                                    </vs-row> -->
                                </div>
                            </vs-col>
                        </vs-row>
                        <vs-row class="gap">
                            <vs-col
                                vs-w="12"
                                style="display: flex; justify-content: center"
                            >
                                <vs-button
                                    color="success"
                                    type="filled"
                                    @click="splitreceipt"
                                    >submit</vs-button
                                >
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <vs-popup
                    class="holamundo"
                    :title="
                        this.misceliniousdata.person_name +
                        '-' +
                        this.misceliniousdata.type
                    "
                    :active.sync="miscelinouspopup"
                >
                    <div style="height: 230px">
                        <vs-card>
                            <div>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Purchase Type : </b></span>
                                        <span>{{
                                            this.misceliniousdata.type
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>Can-ID : </b></span>
                                        <span>{{
                                            this.misceliniousdata.identity
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Agreed Cost : </b></span>
                                        <span>{{
                                            this.misceliniousdata.agreed_cost
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>Amount Paid : </b></span>
                                        <span>{{
                                            this.misceliniousdata.amount_paid
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Amount Pending : </b></span>
                                        <span>{{
                                            this.misceliniousdata.amount_pending
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span
                                            ><b
                                                >Purchase Added By [Spoc] :
                                            </b></span
                                        >
                                        <span>{{
                                            this.misceliniousdata
                                                .enrollment_added_by_name
                                        }}</span></vs-col
                                    >
                                </vs-row>
                            </div>
                        </vs-card>
                        <vs-row class="gap">
                            <vs-col
                                vs-w="12"
                                style="display: flex; justify-content: center"
                            >
                                <vs-button
                                    color="danger"
                                    type="filled"
                                    @click="RemoveMiscelinious()"
                                    >Delete</vs-button
                                >
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <vs-popup
                    class="holamundo"
                    :title="
                        this.deletepurchasedata.person_name +
                        '-' +
                        this.deletepurchasedata.type
                    "
                    :active.sync="deletepurchasepopup"
                >
                    <div style="height: 230px">
                        <vs-card>
                            <div>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Purchase Type : </b></span>
                                        <span>{{
                                            this.deletepurchasedata.type
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>Can-ID : </b></span>
                                        <span>{{
                                            this.deletepurchasedata.identity
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Agreed Cost : </b></span>
                                        <span>{{
                                            this.deletepurchasedata.agreed_cost
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>Amount Paid : </b></span>
                                        <span>{{
                                            this.deletepurchasedata.amount_paid
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Amount Pending : </b></span>
                                        <span>{{
                                            this.deletepurchasedata.amount_pending
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span
                                            ><b
                                                >Purchase Added By [Spoc] :
                                            </b></span
                                        >
                                        <span>{{
                                            this.deletepurchasedata
                                                .enrollment_added_by_name
                                        }}</span></vs-col
                                    >
                                </vs-row>
                            </div>
                        </vs-card>
                        <vs-row class="gap">
                            <vs-col
                                vs-w="12"
                                style="display: flex; justify-content: center"
                            >
                                <vs-button
                                    color="danger"
                                    type="filled"
                                    @click="DeletePuchaseData()"
                                    >Delete</vs-button
                                >
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <vs-popup
                    class="holamundo"
                    :title="
                        this.delete_receipt_data.person_name  "
                    :active.sync="delete_receipt_popup"
                >
                    <div style="height: 150px">
                       <vs-card>
                            <div>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Can-ID : </b></span>
                                        <span>{{
                                            this.delete_receipt_data.identity
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>Amount : </b></span>
                                        <span>{{
                                            this.delete_receipt_data.amount
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>MR Number: </b></span>
                                        <span>{{
                                            this.delete_receipt_data.mr_number
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>City: </b></span>
                                        <span>{{
                                            this.delete_receipt_data.city
                                        }}</span></vs-col
                                    >
                                </vs-row>
                            </div>
                        </vs-card>
                        <vs-row class="gap">
                            <vs-col
                                vs-w="12"
                                style="display: flex; justify-content: center"
                            >
                                <vs-button
                                    color="danger"
                                    type="filled"
                                    @click="deleteReceipt(delete_receipt_data.purchase_id, delete_receipt_data.id)"
                                    >Delete</vs-button
                                >
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <vs-popup class="holamundo" title="Are you sure?" :active.sync="popupOpen">
                    <vs-row style="margin-top: 2%">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                        <b>{{ text }}</b>
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-top: 6%; margin-bottom: 6%" v-if="crediteNote">
                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                            <p style="display: contents">
                            <vs-icon icon="calendar_today"></vs-icon>&nbsp;
                            <span class="schedule_title">&nbsp; Credit Note Date :</span>
                        </p></vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                            <datepicker :disabledDates="disabledDates" placeholder="Select Date" :format="customFormatter" v-model="selected_date" style="
                                position: fixed;
                                z-index: 2000;
                                width: 150px;
                                "></datepicker>
                        </vs-col>
                    </vs-row>
                    
                    <vs-row style="margin-top: 2%">
                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                        <vs-button color="danger" type="border" @click="popupOpen = false"
                            >Cancel</vs-button
                        >
                        </vs-col>
                        <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="6"
                        >
                        <vs-button
                            color="success"
                            type="border"
                            @click="defualterRequest(defaulterPayload)"
                            >Yes</vs-button
                        >
                        </vs-col>
                    </vs-row>
                </vs-popup>
            </div>
            <vs-row style="margin-top: 3%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="flex-end"
                    vs-w="12"
                >
                    <vs-pagination
                        :total="tablelinks"
                        v-model="currentpage"
                    ></vs-pagination>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>
<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import eventbus from "../components/eventbus";
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
export default {
    mounted() {
        this.getSpoc();
        eventbus.$on("reload-receipts", () => {
            this.getReceipts();
        });
    },
    components: {
        vSelect,
        Datepicker,
    },
    data() {
        return {
            disabledDates: {
                to: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                from: new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate()),
            },
            crediteNote: false,
            selected_date: new Date(),
            selected_purchase: '',
            delete_receipt_popup: false,
			serchidentity: "",
			transferAmount: "",
			mwb: {},
			emails: [],
			mobiles: [],
            transfer_receipt_details: false,
            split_receipt_details: false,
            transfer_receipt_popup: false,
            identity: "",
            agreed_fees: "",
            course: "",
            select1: 3,
            options1: [
                { text: "IT", value: 0 },
                { text: "Blade Runner", value: 2 },
                { text: "Thor Ragnarok", value: 3 },
            ],
            value1: "",
            value2: "",
            confirm_agreed_fee: false,
            agreed_fee_popup: false,
            logged_in_user_id: "",
            splittooltip: "Testing",
            misceliniousdata: "",
            miscelinouspopup: false,
            splitreceiptpopup: false,
            visible: false,
            receipts: [],
            currentpage: 1,
            tablelinks: 1,
            canid: '',
            city: "",
            cities: [],
            registeredSpoc: "",
            identityCityCode: "",
            activeConfirm: false,
            selectedReceiptForSplit: null,
            split_wise_course: '',
            split_wise_data: '',
            splitdata: {
                person_name: "",
                transaction_type: "",
                transaction_id: "",
                amount: "",
                receipt_date: "",
                added_by_name: "",
            },
            purchase_type: "",
            purchase_types: [],
            // purchase_types: ["Miscellaneous", "Additional Purchase", "Course"],
            purchase_amount: null,
			TotalLeadData: "",
			Transfercourse: "",
            Transfercoursetypes: [
                "CPA",
                "CPA-AA",
                "USP",
                "AICPA",
                "CMA",
                "CFA",
                "FRM",
                "CPA+USP",
                "IIML-FA",
                "IIML-FT",
                "IIML-SF",
                "IIML-DA",
                "IIML-HR",
                "IITR-BF",
                "IITR-DB",
                "IITM-AA",
                "IIMK-CX",
                "IITM-FS",
                "IITR-CC",
                "IIMK-FT",
                "IIML-AB",
                "IIML-SH",
                "IITJ-DE",
                "XLRI-HR",
                "XLRI-SH",
                "IIMI-BA",
                "XLRI-DM",
                "IIML-PM",
                "IIMI-AA",
            ],
            delete_receipt_data: {},
            Transactiontypes:["course","Additional Purchase","Miscellaneous Purchase"],
            transaction_type: "",
            deletepurchasedata: "",
            deletepurchasepopup: false,
            text: "",
            defaulterPayload: "",
            popupOpen: false,
            purchance_data: '',
        };
    },
    watch: {
        purchase_type(val){
            this.selected_purchase = '';
            if(val == 'Course'){
                this.getSplitWiseData();
            }else{
                this.split_receipt_details = false;
            }
        },
        currentpage() {
            console.log(this.canid);

            if (this.canid === null || this.canid === "") {
                this.getReceipts();
                console.log("can  iud");
            } else if (this.canid !== null || this.canid !== "") {
                this.getReceipts(this.canid);
                console.log("can non iud");
            }
        },
        city: function (val) {
            this.identityCityCode = val.substring(0, 1).toUpperCase();
        },
    },
    methods: {
        customFormatter(date) {
            return moment(date).format("DD-MMM-YYYY");
        },
        deleteReceipt(purchase_id, receipt_id){
              let obj = {
                  purchase_id: purchase_id,
                  receipt_id: receipt_id
              };
            console.log("obj", obj);
            axios
                .post(`${constants.SERVER_API}deleteReceipt`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    if(response.data.status === "success"){
                        this.$vs.notify({
							title: response.data.message,
							color: "success",
						});
                    }
                    this.getReceipts();
                    this.delete_receipt_popup = false;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getdefaulterColor(fetch) {
            console.log("fetch", fetch.defaulter);

            switch (fetch.defaulter) {
                case 0:
                return ""
                case 1:
                return "danger";
            }
        },
        sumOfAmount(data) {
            // console.log("amount", data)
            var msgTotal = data.reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
            // console.log('Total Messages:', msgTotal);
            return msgTotal;
        },
        getSum(val1, val2){
            return val1+val2;
        },
		clear() {
			this.transferAmount = "";
			this.serchidentity = "";
			this.mwb = {};
			this.Transfercourse = "";
            this.transaction_type = "";
		},
        transferreceiptpopup(item) {
			// this.clear();
			// console.log("item", item)
			// this.transfer_receipt_details = false;
			// this.TotalLeadData = item;
			// this.transferAmount = this.sumOfAmount(item.receipts);
            // this.transfer_receipt_popup = true;
            this.clear();
			console.log("item", item)
			this.transfer_receipt_details = false;
			this.TotalLeadData = item;
			this.transferAmount = this.TotalLeadData.amount;
            this.transfer_receipt_popup = true;

        },
        transferreceiptdetails() {
			let obj = {
				identity: this.serchidentity,
                purchase_id: this.TotalLeadData.purchase_id
			};
			if (obj.identity !== "") {
			this.$vs.loading()
				axios
                .get(`${constants.SERVER_API}getPurchasesOfCandidate`, {
					params: obj,
					headers: {
					Authorization: `Bearer ${localStorage.userAccessToken}`,
					},
				})
				.then((response) => {
						if (response.data.response !== "No purchases found") {
							console.log("purchases", response.data.response);
                            this.purchance_data = response.data.response;
                            response.data.response.forEach(element => {
                                if (element.identity == this.serchidentity) {
                                    this.mwb = element
                                }
                            });
							this.transfer_receipt_details = true;
						}else {
						this.$vs.notify({
							title: response.data.response,
							color: "error",
						});
					}
					this.$vs.loading.close()
				})
				.catch((error) => {
					this.handleError(error);
					this.$vs.loading.close()
				});
			} else {
				this.$vs.loading.close();
				this.$vs.notify({
				title: "Please Enter Can-ID",
				color: "danger",
				});
			}
		},

        getSplitWiseData() {
			let obj = {
				identity: this.split_wise_data.identity,
                purchase_id: this.split_wise_data.purchase_id
			};
			this.$vs.loading()
				axios
                .get(`${constants.SERVER_API}getPurchasesOfCandidate`, {
					params: obj,
					headers: {
					Authorization: `Bearer ${localStorage.userAccessToken}`,
					},
				})
				.then((response) => {
						if (response.data.response !== "No purchases found") {
							console.log("purchases", response.data.response);
                            
                            // this.purchance_data = response.data.response;
                            let enrollment_found = [];
                            response.data.response.forEach(element => {
                                if(element.enrollment_id != null){
                                    enrollment_found.push(element);
                                }
                                if (element.identity == this.serchidentity) {
                                    this.mwb = element
                                }
                            });
                            this.purchance_data = enrollment_found;
							this.split_receipt_details = true;
						}else {
						this.$vs.notify({
							title: response.data.response,
							color: "error",
						});
					}
					this.$vs.loading.close()
				})
				.catch((error) => {
					this.handleError(error);
					this.$vs.loading.close()
				});
		},
		// transferreceiptdetails() {
		// 	this.$vs.loading()
		// 	let obj = {
		// 		identity: this.serchidentity,
		// 		type: "identity",
		// 	};
		// 	this.emails = [];
		// 	this.mobiles = [];
		// 	if (obj.identity !== "") {
		// 		axios
		// 		.get(`${constants.SERVER_API}getLeadDetailsForChangeLeadToDND`, {
		// 			params: obj,
		// 			headers: {
		// 			Authorization: `Bearer ${localStorage.userAccessToken}`,
		// 			},
		// 		})
		// 		.then((response) => {
		// 			if(response.data.message !== "Not Authorised to change details"){
		// 				if (response.data.response !== "No Records found") {
		// 					let emails = response.data.response.email;
		// 					let mobiles = response.data.response.mobile;
		// 					this.mwb = response.data.response.mwb;
		// 					this.transfer_receipt_details = true;
		// 					console.log("response emails", emails);
		// 					console.log("response mobiles", mobiles);
		// 					console.log("response mwb", this.mwb);
		// 					emails.forEach((email) => {
		// 						this.emails.push(email.email);
		// 					});
		// 					mobiles.forEach((mobile) => {
		// 						this.mobiles.push(mobile.last_ten_digits);
		// 					});
		// 					console.log("response emails", this.emails);
		// 					console.log("response mobiles", this.mobiles);
		// 					this.dndData = true;
		// 				} else {
		// 					this.$vs.notify({
		// 						title: response.data.response,
		// 						color: "warning",
		// 					});
		// 				}
		// 			}else {
		// 				this.$vs.notify({
		// 					title: response.data.message,
		// 					color: "warning",
		// 				});
		// 			}
		// 				this.$vs.loading.close()
		// 		})
		// 		.catch((error) => {
		// 			this.handleError(error);
		// 		});
		// 	} else {
		// 		this.$vs.notify({
		// 		title: "Please Enter Can-ID",
		// 		color: "danger",
		// 		});
		// 	}
		// },
		TransferReceipt() {
            // if ((this.transaction_type == '' || this.transaction_type == null) && (this.Transfercourse == '' || this.Transfercourse == null)) {
            //     this.$vs.notify({
            //         title: "Please select 'Type and Course'",
            //         text: "",
            //         color: "danger",
            //     });
            // }else if(this.transaction_type == '' || this.transaction_type == null){
            //     this.$vs.notify({
            //         title: "Please select 'Type'",
            //         text: "",
            //         color: "danger",
            //     });
            // }else if(this.Transfercourse == '' || this.Transfercourse == null){
            //     this.$vs.notify({
            //         title: "Please select 'Course'",
            //         text: "",
            //         color: "danger",
            //     });
            // }else{
			this.$vs.loading()
			// let obj = {
			// 	oldlead_identity : this.TotalLeadData.identity,
			// 	oldlead_name : this.TotalLeadData.person_name,
			// 	transferable_identity : this.mwb.identity,
			// 	transferable_lead_name : this.mwb.person_name,
			// 	receipt_id : this.TotalLeadData.id,
			// 	transferable_lead_person_id : this.mwb.person_id,
			// 	course : this.Transfercourse,
			// 	type : this.transaction_type
			// }
            let obj = {
				receipt_id : this.TotalLeadData.id,
                from_purchase_id : this.TotalLeadData.purchase_id,
                from_purchase_identity : this.TotalLeadData.identity,
                to_purchase_id : this.selected_purchase.id,
                to_purchase_identity : this.selected_purchase.identity,
			}
			console.log("transfer Purchase obj", obj)
			axios
                .post(`${constants.SERVER_API}transferPurchaseAndReceipt`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
					this.$vs.loading.close()
                    if (response.data.status === "success") {

						this.$vs.loading.close()
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
						this.transfer_receipt_popup = false;
						this.getReceipts();
                    } else if (response.data.status === "error") {
						this.$vs.loading.close()
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
            // }
		},
        split(tr, item) {
            if(item.enrollment_id != null){
                this.purchase_types = ["Miscellaneous", "Additional Purchase", "Course"];
            }else{
                this.purchase_types = ["Miscellaneous", "Additional Purchase"];
            }
            this.split_wise_course = '',
            this.split_wise_data = tr
            if(tr.course != null){
                this.split_wise_course = tr.course
            }
            this.purchase_type = '';
            this.splitdata.id = tr.id;
            this.splitdata.person_name = tr.person_name;
            this.splitdata.transaction_type = tr.transaction_type;
            this.splitdata.transaction_id = tr.transaction_id;
            this.splitdata.amount = tr.amount;
            this.splitdata.receipt_date = tr.receipt_date;
            this.splitdata.added_by_name = tr.added_by_name;
            this.splitreceiptpopup = true;
            this.split_receipt_details = false;
        },
        resendReceipt(tr) {
            let obj = {
                receipt_id: tr.id,
            };
            console.log("obj", obj);
            axios
                .post(`${constants.SERVER_API}resendReceiptEmail`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                    } else if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        splitreceipt() {
                let obj = {
                        receipt_id: this.splitdata.id,
                        amount: this.purchase_amount,
                        purchase_type: this.purchase_type,
                    };
                if(this.purchase_type == 'Course'){
                    obj.purchase_id = this.selected_purchase.id;
                }
                console.log("objjj", obj);
                if(this.purchase_amount == '' || this.purchase_amount == null){
                    this.$vs.loading.close();
                    this.$vs.notify({
                    title: "Amount should be greater than zero",
                    color: "danger",
                    });
                }else{
                    if(this.purchase_type == 'Course' && this.selected_purchase == ''){
                        this.$vs.loading.close();
                        this.$vs.notify({
                        title: "Please select a purchase",
                        color: "danger",
                        });
                    }else{
                        axios
                        .post(`${constants.SERVER_API}splitReceipt`, obj, {
                            headers: {
                                Authorization: `Bearer ${localStorage.userAccessToken}`,
                            },
                        })
                        .then((response) => {
                            if (response.data.status === "success") {
                                this.$vs.notify({
                                    title: response.data.message,
                                    text: "",
                                    color: "success",
                                });
                                this.purchase_type = "";
                                this.purchase_amount = "";
                                this.splitreceiptpopup = false;
                            } else if (response.data.status === "error") {
                                this.$vs.notify({
                                    title: response.data.message,
                                    text: "",
                                    color: "danger",
                                });
                            }
                            this.getReceipts();
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                    }
                }
               
        },
        refreshReceipts() {
            this.canid = "";
            this.city = "";
            this.currentpage = 1;
            this.getReceipts();
        },
        Removepurchase(data) {
            console.log("item", data);
            this.misceliniousdata = data;
            this.miscelinouspopup = true;
        },
        DeletePurchase(data) {
            console.log("item", data);
            this.deletepurchasedata = data;
            this.deletepurchasepopup = true;
        },
        deleteReceiptData(receipt_data){
            this.delete_receipt_popup = true;
            this.delete_receipt_data = receipt_data;
        },
        DeletePuchaseData() {
            this.$vs.loading();
            let obj = {
                purchase_id: this.deletepurchasedata.id,
            };
            console.log("obj", obj);
            axios
                .post(`${constants.SERVER_API}deletePurchase`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    this.$vs.loading.close();
                    console.log(response);
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                        this.deletepurchasepopup = false;
                        this.miscelinouspopup = false;
                        this.getReceipts();
                    } else if (response.data.status === "warning") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "warning",
                        });
                    } else if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        RemoveMiscelinious() {
            let obj = {
                purchase_id: this.misceliniousdata.id,
            };
            console.log("obj", obj);
            axios
                .post(`${constants.SERVER_API}deleteMiscPurchase`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                        this.miscelinouspopup = false;
                        this.getReceipts();
                    } else if (response.data.status === "warning") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "warning",
                        });
                    } else if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getrole(providedrole) {
            if (localStorage.getItem("role") === providedrole) {
                return true;
            } else {
                return false;
            }
        },
        addenrollment(item) {
              console.log("emitted", item.receipts.length);
            eventbus.$emit("open-AddEnrollment", item);
        },
        openAddReceipt() {
            // alert(this.canid);
            console.log("can id --- open",this.canid);
            this.getReceipts();
            /* if (localStorage.getItem("role") === "cm4") {
        eventbus.$emit(
          "open-add-receipt-popup",
          `${this.city.charAt(0)}-${this.canid}`
        );
      } else {
        eventbus.$emit(
          "open-add-receipt-popup",
          `${this.identityCityCode}-${this.canid}`
        );
      } */
        },
        getSpoc() {
            let url = null;
            if (this.getrole("exed2")) {
                url = `${constants.SERVER_API}getExed`;
            } else {
                url = `${constants.SERVER_API}getSpocs`;
            }
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.logged_in_user_id = response.data.logged_in_user_id;
                    this.registeredSpoc = response.data.logged_in_user_id;
                    response.data.spocs.forEach((spoc) => {
                        if (spoc.id === this.registeredSpoc) {
                            // console.log(spoc, "found the spoc");
                            this.identityCityCode = spoc.city.charAt(0);
                            // console.log(this.identityCityCode);
                        }
                    });
                    this.extractSpocByCity(response.data.spocs);
                    this.getReceipts();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        markAsDefaulter(payload) {
            console.log("hello",payload);
            if(payload.defaulter == 0){
                this.text = "Are you sure you want to mark this lead as a defaulter?";
                this.selected_date = new Date();
                this.crediteNote = true;
            }
            if(payload.defaulter == 1){
                this.crediteNote = false;
                this.text = "Are you sure you want to remove this lead from defaulter?";
            }
            this.defaulterPayload = payload;
            this.popupOpen = true;
        },
        defualterRequest(payload) {
            this.$vs.loading();
            let url = "";
            
            let obj = {
                purchase_id: payload.id,
                mwb_id: payload.mwb_id,
                person_id: payload.person_id,
                identity: payload.identity,
                enrollment_id: payload.enrollment_id,
                user_id: localStorage.getItem("spoc_id"),
                action: 'defaulter_added',
            };
            if(payload.defaulter == 0){
                url = `${constants.SERVER_API}markAsDefaulter`;
                obj.credit_note_date = moment(this.selected_date).format("YYYY-MM-DD")
            }
             if(payload.defaulter == 1){
                url = `${constants.SERVER_API}removeFromDefaulter`;
                obj.action = 'defaulter_removed'
            }
            console.log("defualterRequestObj", obj, url);
            axios
               .post(url, obj, {
                headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                this.$vs.loading.close();
                console.log(response);
                this.handleNotification(response);
                if (response.data.status === "success") {
                    this.open = false;
                    this.popupOpen = false;
                    this.getReceipts();
                }
                })
                .catch((error) => {
                this.handleError(error);
                });
        },
        getUserId(userID) {
            if (this.logged_in_user_id === userID) {
                return true;
            } else {
                return false;
            }
        },
        CheckTeam(team){
             if (localStorage.getItem("team") === team) {
                return true;
            } else {
                return false;
            }
        },
        extractSpocByCity(response) {
            // console.log(response);
            var unique = response
                .map((name) => {
                    return {
                        count: 1,
                        name: name.city,
                    };
                })
                .reduce((a, b) => {
                    a[b.name] = (a[b.name] || 0) + b.count;
                    return a;
                }, {});
            let unoderedCities = [];
            for (var key in unique) {
                if (unique.hasOwnProperty(key)) {
                    unoderedCities.push(key);
                }
            }
            this.cities = this.sortArrayAlphabetically(unoderedCities);
            // this.city = this.cities[0];
            // this.getEnrollmentstoApprove();
        },
        getReceipts() {
            this.$vs.loading();
            this.visible = false;
            let idstring = "";
            let pagestring = "";
            let url = "";
             console.log("can id1----",this.canid);
            if (this.canid != '') {
                pagestring = `page=${this.currentpage}`;
                // idstring = `identity=${this.identityCityCode}-${canid}`;
                console.log("can id2----",this.canid);
                idstring = `identity=${this.canid}`;
                url = `${constants.SERVER_API}getAllPurchasesForReceipts?${pagestring}&${idstring}`;
            } else {
                console.log("can id3----",this.canid);
                pagestring = `page=${this.currentpage}`;
                url = `${constants.SERVER_API}getAllPurchasesForReceipts?${pagestring}`;
            }
            // let url = `${constants.SERVER_API}getAllPurchasesForReceipts?${pagestring}&${idstring}`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("purchase id receipts",response);
                    this.$vs.loading.close();
                    if (response.data.status !== "success") {
                        this.handleNotification(response);
                    } else {
                        this.tablelinks = response.data.data.last_page;
                        this.receipts = response.data.data.data;
                        this.visible = true;
                    }
                    //   this.amount_paid = response.data.amount_paid;
                    //   this.pending_amount = response.data.amount_pending;
                    //   this.tablelinks = response.data.last_page;
                    //   this.visible = true;
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
    },
};
</script>

<style>
  .odd {background-color: red;}
  .even {background-color: green;}
[data-tooltip],
.tooltip {
    position: relative;
    cursor: pointer;
}
/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: none;
}
/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}
/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
}
/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
    z-index: 1000;
    padding: 8px;
    width: 160px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    font-size: 14px;
    line-height: 1.2;
}
/* Directions */
/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
    bottom: 100%;
    left: 50%;
}
[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
    margin-left: -6px;
    margin-bottom: -12px;
    border-top-color: #000;
    border-top-color: hsla(0, 0%, 20%, 0.9);
}
/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
    margin-left: -80px;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    transform: translateY(-12px);
}
/* Left */
.tooltip-left:before,
.tooltip-left:after {
    right: 100%;
    bottom: 50%;
    left: auto;
}
.tooltip-right:before,
.tooltip-right:after {
    bottom: 50%;
    left: 100%;
}
.tooltip-right:before {
    margin-bottom: 0;
    margin-left: -12px;
    border-top-color: transparent;
    border-right-color: #000;
    border-right-color: hsla(0, 0%, 20%, 0.9);
}
.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    transform: translateX(12px);
}
.TransferpopUp .vs-popup{
    width: 45%;
}
</style>
